<template>
  <div id="app">
    <navbar :class="{ down: down }" />
    <router-view />
  </div>
</template>

<script>
import Navbar from "./components/partiels/Navbar.vue";

export default {
  components: {
    Navbar,
  },
  data() {
    return {
      down: false,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 50) {
        this.down = true;
      } else {
        this.down = false;
      }
    },
  },
};
</script>
