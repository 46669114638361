<template>
  <div id="dashboard">
    <!-- <Sidebar /> -->
    <sidebar-menu :menu="menu" @toggle-collapse="onToggleCollapse">
      <span slot="toggle-icon"
        ><i class="fa fa-toggle-on" aria-hidden="true"></i
      ></span>
    </sidebar-menu>
    <div :class='{ "dashboard-content" : !collapsed}'>
      <div class="container-fluid ml-5 pr-5 mr-4">
        <Heading />
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Heading from "../components/dashboard/partiels/Heading.vue";
// import Sidebar from "../components/partiels/Sidebar.vue";
import { SidebarMenu } from "vue-sidebar-menu";

export default {
  components: { Heading, SidebarMenu },
  data() {
    return {
      collapsed : false,
      menu: [
        {
          header: false,
          title: "Main Navigation",
          hiddenOnCollapse: true,
        },
        {
          href: "/users",
          title: "Users",
          icon: "fas fa-user",
        },
        {
          href: "/messages",
          title: "Messages",
          icon: "fas fa-list-alt",
          badge: {
            text: "new",
            class: "vsm--badge_default",
            // attributes: {}
            // element: 'span'
          },
        },
        {
          href: "/shops",
          title: "Shops",
          icon: "fas fa-shopping-bag",
        },
        {
          href: "/services",
          title: "Services",
          icon: "fa fa-wrench",
        },
        {
          href: "/orders",
          title: "Orders",
          icon: "fa fa-wrench",
        },
        {
          href: "/notifications",
          title: "Notifications",
          icon: "fa fa-bell",
        },
      ],
    };
  },
  methods: {
    onToggleCollapse(collapsed) {
      this.collapsed = collapsed;
      console.log(collapsed);
    },
    // onItemClick(event, item, node) {}
}
};
</script>
<style lang="scss">
@import "vue-sidebar-menu/src/scss/vue-sidebar-menu.scss";

.v-sidebar-menu {
  padding-top: 100px;
  background-color: #000dff !important;
}
.v-sidebar-menu.vsm_expanded {
  max-width: 270px !important;
}

.v-sidebar-menu {
  .vsm--item,
  .vsm--link {
    // background-color: #fff !important;
    // color: #000dff !important;
  }
}

.navbar {
  // padding-left: 270px;
}
.bg-dark {
  background-color: #000dff !important;
}
@media (min-width: 1200px) {
  .dashboard-content {
    transition: all 0.2s ease-out;
    padding-left: 270px;
  }
}
@media (max-width: 1199.98px) {
}
</style>
