<template name="delete-account">
  <div>
    <div class="card border border-primary mx-auto mt-5 container">
      <div class="card-body">
        <h1 class="text-primary text-uppercase">Delete my Carixin account</h1>
        <p>
          You can delete your account from the Carixin app. contact us through
          the email below:
          <strong
            ><a href="mailto:Contaxt@bachirsouldi.com"
              >Contaxt@bachirsouldi.com</a
            ></strong
          >
        </p>
        <div>
          <h5 class="text-primary text-uppercase">
            Delete your account from the app
          </h5>
          <ol>
            <li>Open the Uber app and tap the menu icon at the top left</li>
            <li>Select “Settings”</li>
            <li>Tap “Delete Account”</li>
            <li>
              Follow the remaining steps in the app to complete the process
            </li>
          </ol>
        </div>
        <p>
          After completing the process above, your account will be immediately
          deactivated. After 30 days it will be permanently deleted, Carixin may
          retain certain information after account deletion as required or
          permitted by law. If you’re having trouble deleting your account,
          contact us through the email below:
          <strong
            ><a href="mailto:Contaxt@bachirsouldi.com"
              >Contaxt@bachirsouldi.com</a
            ></strong
          >
        </p>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../components/partiels/Footer.vue";

export default {
  components: {
    Footer,
  },
};
</script>
