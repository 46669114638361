<template>
  <div class="users">
      <div class="float-right">
        <button
          type="button"
          class="btn btn-primary waves-effect"
          data-toggle="modal"
          data-target="#userForm"
          @click="
            resetForm();
            setStatus(false);
          "
        >
          <i class="fa fa-plus mr-2" aria-hidden="true"></i>Add User
        </button>
      </div>
      <div class="clearfix"></div>

      <div class="card">
        <div class="card-header bg-primary text-center text-white">
          <h1>Users</h1>
        </div>
        <div class="card-body table-responsive">
          <div class="clearfix"></div>
          <table class="table" id="example">
            <thead>
              <tr>
                <th>#</th>
                <th>Profile</th>
                <th>Fullname</th>
                <th>Email</th>
                <th>Status</th>
                <th data-mdb-toggle="tooltip" title="Social Security Number">
                  Ssn
                </th>
                <th>Verified</th>
                <th>Type</th>
                <th>actions</th>
              </tr>
            </thead>
            <tbody v-if="users.length > 0">
              <tr v-for="(item, index) in users" :key="item._id">
                <td scope="row">{{ index }}</td>
                <td>
                  <img
                    :src="item.profile"
                    class="rounded-circle img-responsive img-responsive"
                    style="width: 50px"
                    :alt="item.firstname"
                  />
                </td>
                <td scope="row">{{ item.firstname + " " + item.lastname }}</td>
                <td scope="row">{{ item.email }}</td>
                <td v-if="item.disabled">
                  <span class="badge badge-pill badge-danger">Disabled</span>
                </td>
                <td v-else>
                  <span class="badge badge-pill badge-success">Active</span>
                </td>
                <td>
                  <span v-if="item.ssn && item.ssn.length > 0">
                    <!-- Button trigger modal -->
                    <a
                      href="#"
                      class="badge badge-warning"
                      data-toggle="modal"
                      data-target="#ssn_modal"
                      @click="setDocument(item.ssn)"
                      >See documents</a
                    >
                  </span>
                  <span v-else>Not set</span>
                </td>
                <td>
                  <span
                    class="badge badge-pill badge-success"
                    v-if="item.verified"
                    >Verified</span
                  >
                  <span class="badge badge-pill badge-danger" v-else
                    >Not verified</span
                  >
                </td>
                <td>
                  <span
                    :class="[
                      'badge',
                      'badge-pill',
                      'badge-' + setPillColor(item.role),
                    ]"
                    >{{ item.role }}</span
                  >
                </td>
                <td>
                  <!-- show button -->
                  <div class="btn-group btn-group-sm">
                    <button
                      class="btn btn-success btn-sm dropdown-toggle"
                      type="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="fa fa-check" aria-hidden="true"></i>
                    </button>
                    <div class="dropdown-menu">
                      <a
                        class="dropdown-item"
                        href="#"
                        @click="verifyUser(item._id, index, true)"
                        >Verified</a
                      >
                      <a
                        class="dropdown-item"
                        href="#"
                        @click="verifyUser(item._id, index, false)"
                        >Unverified</a
                      >
                    </div>
                  </div>
                  <button
                    type="button"
                    class="btn btn-primary btn-sm tetx-white"
                    data-toggle="modal"
                    data-target="#userForm"
                    @click="
                      fetchUserPerId(item._id);
                      setStatus(true);
                    "
                  >
                    <i class="fa fa-eye" aria-hidden="true"></i>
                  </button>
                  <!-- delete button -->
                  <div class="btn-group btn-group-sm">
                    <button
                      class="btn btn-danger btn-sm dropdown-toggle"
                      type="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      v-if="item.role != 'admin'"
                    >
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </button>
                    <div class="dropdown-menu">
                      <a
                        class="dropdown-item"
                        href="#"
                        @click="disableUser(item._id, index, true)"
                        >Disable</a
                      >
                      <a
                        class="dropdown-item"
                        href="#"
                        @click="disableUser(item._id, index, false)"
                        >Enable</a
                      >
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-if="!loading">
                <td colspan="4" class="text-center">No item available</td>
              </tr>
              <tr v-else>
                <td colspan="4" class="text-center">
                  <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Add User -->
      <div
        class="modal fade"
        id="userForm"
        tabindex="-1"
        role="dialog"
        aria-labelledby="modelTitleId"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ show ? "Show" : "Add" }} User</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form method="post" enctype="application/x-www-form-urlencoded">
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-6">
                    <!-- FirstName -->
                    <div class="md-form">
                      <input
                        type="text"
                        id="firstname"
                        v-model.trim="form.firstname"
                        class="form-control"
                        :class="{ disabled: show }"
                      />
                      <label class="active" for="firstname">Firstname</label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <!-- lastName -->
                    <div class="md-form">
                      <input
                        type="text"
                        id="lastname"
                        v-model.trim="form.lastname"
                        class="form-control"
                        :class="{ disabled: show }"
                      />
                      <label class="active" for="lastname">Lastname</label>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <!-- Email -->
                    <div class="md-form">
                      <input
                        type="email"
                        id="email"
                        v-model.trim="form.email"
                        class="form-control"
                        :class="{ disabled: show }"
                      />
                      <label class="active" for="email">Email</label>
                    </div>
                  </div>

                  <div class="col-md-12" v-if="!show">
                    <!-- Password -->
                    <div class="md-form position-relative">
                      <input
                        :type="[!eye ? 'password' : 'text']"
                        id="password"
                        v-model.trim="form.password"
                        class="form-control"
                        :class="{ disabled: show }"
                      />
                      <span class="eye" @click="eyeToggle"
                        ><i
                          :class="['fa', !eye ? 'fa-eye' : 'fa-eye-slash']"
                          aria-hidden="true"
                        ></i
                      ></span>
                      <label class="active" for="password">Password</label>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <!-- Address -->
                    <div class="md-form">
                      <input
                        type="text"
                        id="address"
                        v-model.trim="form.address"
                        class="form-control"
                        :class="{ disabled: show }"
                      />
                      <label class="active" for="address">Address</label>
                    </div>
                  </div>
                  <div class="col-md-12" v-if="!show">
                    <!-- social security number -->
                    <div class="md-form">
                      <input
                        type="text"
                        id="social-security-number"
                        class="form-control"
                        :class="{ disabled: show }"
                        v-model.trim="form.ssn"
                      />
                      <span class="text-muted text-danger" v-if="!resPattern">
                        Social Security Number non valid
                      </span>
                      <label class="active" for="social-security-number"
                        >Social Security Number</label
                      >
                    </div>
                  </div>
                  <!-- role -->
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="active" for="filterby">Filter By : </label>
                      <select
                        class="form-control"
                        :class="{ disabled: show }"
                        v-model.trim="form.role"
                        id="filterby"
                      >
                        <option value="admin" selected>Admin</option>
                        <option value="mechanic">Mechanic</option>
                        <option value="customer">Customer</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <!-- UserProfile -->
                    <!-- <div class="input-group mb-2">
                      <label class="active" for="user-upload">Profile: </label>
                      <div class="input-group-prepend">
                        <span class="input-group-text" id="showcase-file"
                          >Upload</span
                        >
                      </div>
                      <div class="custom-file">
                        <input
                          type="file"
                          class="custom-file-input"
                          id="user-upload"
                          ref="file"
                          aria-describedby="user-file"
                        />
                        <label class="custom-file-label" for="user-upload"
                          >Choose Image</label
                        >
                      </div>
                    </div> -->
                  </div>
                </div>

                <!-- Save button -->
                <button
                  class="
                    btn btn-outline-info btn-rounded btn-block
                    z-depth-0
                    my-4
                    waves-effect
                  "
                  type="button"
                  v-if="!show"
                  @click="createUser()"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- User Documents Modal -->
      <div
        class="modal fade"
        id="ssn_modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="ssn_modal"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">User's document</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <img
                v-for="(img, index) in documents"
                :key="index"
                :src="img"
                class="img-thumbnail my-2"
              />
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import axios from "axios";
// import { auth } from "@/firebase";
// import $ from "jquery";
import swal from "sweetalert";

export default {
  data() {
    return {
      users: [],
      form: {
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        address: "",
        ssn: "",
        disabled: "",
        role: "admin",
      },
      error: {
        errorCode: "",
        errorMessage: "",
      },
      loading: true,
      eye: false,
      ssn: "",
      documents: [],
      resPattern: true,
      datatable: null,
      show: false,
    };
  },
  methods: {
    fetchUsers() {
      let self = this;
      axios
        .get("/api/users?limit=1000", this.options)
        .then((response) => {
          this.users = response.data[1].data;
          this.loading = false;
          if (typeof window.$.fn.DataTable != "undefined") {
            window.$(document).ready(function() {
              self.datatable = window.$("#example").DataTable();
            });
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    setPillColor(type) {
      switch (type) {
        case "admin":
          return "success";
        case "mechanic":
          return "primary";
        case "customer":
          return "info";
        default:
          return "danger";
      }
    },
    eyeToggle() {
      this.eye = !this.eye;
    },
    createUser() {
      this.resetError();
      // auth
      //   .createUserWithEmailAndPassword(this.form.email, this.form.password)
      //   .then((userCredentiels) => {
      //     var user = userCredentiels.user;
      axios
        .post("/api/users", {
          firstname: this.form.firstname,
          lastname: this.form.lastname,
          email: this.form.email,
          password: this.form.password,
          address: this.form.address,
          role: this.form.role,
        })
        .then(() => {
          swal("User verified successfully", {
            icon: "success",
          });
        })
        .catch((error) => {
          swal("Oops", error.toString(), "error");
        });
      //   console.log("user created", user);
      // })
      // .catch((error) => {
      //   this.error.errorCode = error.code;
      //   this.error.errorMessage = error.message;
      // });
    },
    fetchUserPerId(id) {
      axios
        .get("/api/users/" + id)
        .then((response) => {
          this.form.firstname = response.data.firstname;
          this.form.lastname = response.data.lastname;
          this.form.email = response.data.email;
          this.form.address = response.data.address;
          this.form.ssn = response.data.ssn;
          this.form.role = response.data.role;
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setDocument(ssn) {
      this.documents = ssn;
    },
    verifyUser(id, index, status) {
      let self = this;
      swal({
        title: "Are you sure?",
        text: "Do you want to set this user verified",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          axios
            .patch("/api/users/" + id, [
              {
                propName: "verified",
                value: status,
              },
            ])
            .then(() => {
              self.users[index]["verified"] = status;
              self.datatable.draw();
              swal("User verified successfully", {
                icon: "success",
              });
            })
            .catch((error) => {
              swal("Oops", error.toString(), "error");
            });
        }
      });
    },
    setStatus(status) {
      this.show = status;
    },
    resetError() {
      this.error.errorCode = "";
      this.error.errorMessage = "";
    },
    disableUser(id, index, status) {
      var self = this;
      swal({
        title: "Are you sure?",
        text: `Do you wanna set this user status ${status} this user`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          console.log(id);
          axios
            .patch("/api/users/account/" + id, {
              disabled: status,
            })
            .then(() => {
              self.users[index]["disabled"] = status;
              self.datatable.draw();
              swal(`Poof! Your user has been set ${status}!`, {
                icon: "success",
              });
            })
            .catch(function(error) {
              swal("Oops", error.toString(), "error");
            });
        } else {
          swal("Your user is safe!");
        }
      });
    },
    resetForm() {
      this.form.firstname = "";
      this.form.lastname = "";
      this.form.email = "";
      this.form.password = "";
      this.form.address = "";
      this.form.ssn = "";
      this.form.role = "admin";
    },
  },
  watch: {
    "form.ssn": function(val) {
      const ssnPattern = /^[0-9]{3}-?[0-9]{2}-?[0-9]{4}$|^\s*$/;
      this.resPattern = ssnPattern.test(val);
    },
  },
  mounted() {
    this.fetchUsers();
  },
};
</script>
