<template>
  <div id="home-dashboard">
    <div class="text-center mx-auto" v-if="loading">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-md-3 mb-4">
        <div class="card bg-primary text-white">
          <div class="card-body">
            <p class="float-right">
              <i class="fa fa-users fa-3x" aria-hidden="true"></i>
            </p>
            <h4>Users :</h4>
            <p class="h4">{{ countUser }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-3 mb-4">
        <div class="card bg-success text-white">
          <div class="card-body">
            <p class="float-right">
              <i class="fa fa-calendar fa-3x" aria-hidden="true"></i>
            </p>

            <h4>Orders :</h4>
            <p class="h4">{{ countOrder }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-3 mb-4">
        <div class="card bg-warning text-white">
          <div class="card-body">
            <p class="float-right">
              <i class="fa fa-wrench fa-3x" aria-hidden="true"></i>
            </p>

            <h4>Services :</h4>
            <p class="h4">{{ countService }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-3 mb-4">
        <div class="card bg-info text-white">
          <div class="card-body">
            <p class="float-right">
              <i class="fa fa-calculator fa-3x" aria-hidden="true"></i>
            </p>
            <h4>Shops :</h4>
            <p class="h4">{{ countShop }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="card text-left">
          <div class="card-body">
            <OrderLine
              :chart-data="orderDateCollection"
              :options="orderOptions"
            />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <h2 class="card-header bg-primary text-white">Users</h2>
          <div class="card-body">
            <div class="chart">
              <UserChart
                :chart-data="userCollection"
                :options="userOptions"
                :styles="myStyles"
              ></UserChart>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <h2 class="card-header bg-primary text-white">Orders</h2>
          <div class="card-body">
            <div class="chart">
              <OrderChart
                :chart-data="orderCollection"
                :options="orderOptions"
                :styles="myStyles"
              ></OrderChart>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <div class="card mt-3">
          <h2 class="card-header bg-primary text-white">Users</h2>
          <div class="card-body">
            <div class="chart">
              <ServiceChart
                :chart-data="userCollection"
                :options="userOptions"
                :styles="myStyles"
              ></ServiceChart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserChart from "./charts/UserChart";
import OrderChart from "./charts/OrderChart";
import ServiceChart from "./charts/ServiceChart";
import OrderLine from "./charts/OrderLine";
// import LineChart from "./charts/LineChart";
import axios from "axios";
import swal from "sweetalert";
import { auth } from "@/firebase";

export default {
  components: {
    UserChart,
    OrderChart,
    ServiceChart,
    OrderLine,
    // LineChart,
  },
  data() {
    return {
      orderDateCollection: {},
      userCollection: {},
      userOptions: {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 0.5,
        scales: {
          y: {
            display: true,
            type: "logarithmic",
            height: 300,
          },
          height: 300,
        },
      },
      orderCollection: {},
      orderOptions: {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 3,
      },
      user: {
        labels: [],
        userPerCategory: [],
      },
      order: {
        labels: [],
        labelsDate: [],
        orderPerStatus: [],
        orderPerDate: [],
      },
      countUser: null,
      countOrder: null,
      countService: null,
      countShop: null,
      loading: true,
    };
  },
  mounted() {
    auth.currentUser
      .getIdToken()
      .then(function (idToken) {
        console.log("******************************************", idToken);
      })
      .catch(function (error) {
        console.log(error);
      });
    axios
      .get("/api/dashboard/users")
      .then((res) => {
        this.loading = false;
        this.countUser = res.data.countUser;
        this.countOrder = res.data.countOrder;
        this.countService = res.data.countService;
        this.countShop = res.data.countShop;
        // user data
        this.user.userPerCategory = res.data.userData.map((e) => e["count"]);

        this.user.labels = res.data.userData.map((e) => e["_id"].toUpperCase());
        // order data
        this.order.orderPerStatus = res.data.orderData.map((e) => e["count"]);
        this.order.labels = res.data.orderData.map((e) =>
          e["_id"].toUpperCase()
        );
        this.order.labelsDate = res.data.orderDataDate.map((e) =>
          e["_id"].toUpperCase()
        );
        this.order.orderPerDate = res.data.orderDataDate.map(
          (e) => e["totalOrders"]
        );

        this.fillDataUser();
        this.fillDataOrder();
      })
      .catch((err) => {
        swal("Oops", err.toString(), "error");
      });
  },
  methods: {
    fillDataUser() {
      this.userCollection = {
        labels: this.user.labels,
        datasets: [
          {
            label: "Total",
            data: this.user.userPerCategory,
            backgroundColor: [
              "rgb(54, 162, 235)",
              "rgb(255, 205, 86)",
              "rgb(255, 99, 132)",
            ],
          },
        ],
        borderWidth: 1,
      };
      this.userOptions = {
        plugins: {
          title: {
            display: true,
            text: "Custom Chart Title",
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 2,
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                beginAtZero: true,
                steps: 1,
                stepValue: 1,
                max: 10,
              },
            },
          ],

          height: 300,
        },
      };
    },
    // order fill data
    fillDataOrder() {
      (this.orderDateCollection = {
        labels: this.order.labelsDate,
        datasets: [
          {
            label: "Order per date",
            data: this.order.orderPerDate,
            borderColor: "#2fe0a8",
            fill: false,
          },
        ],
      }),
        (this.orderCollection = {
          labels: this.order.labels,
          datasets: [
            {
              label: "Total",
              data: this.order.orderPerStatus,
              backgroundColor: [
                "rgb(54, 162, 235)",
                "rgb(255, 205, 86)",
                "rgb(255, 99, 132)",
              ],
            },
          ],
          borderWidth: 1,
        });
      this.orderOptions = {
        plugins: {
          title: {
            display: true,
            text: "Custom Chart Title",
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 2,
        scales: {
          y: {
            display: true,
            min: 0,
            max: 100,
          },
          height: 300,
        },
      };
    },
  },
  computed: {
    myStyles() {
      return {
        "max-height": `400px`,
        position: "relative",
      };
    },
  },
};
</script>

<style lang="scss">
.chart {
  max-width: 100%;
  max-height: 400px;
  margin: auto;
}
</style>
