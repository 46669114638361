var firebase = require("firebase");

const firebaseConfig = {
  apiKey: "AIzaSyDwGsQbeKcCYjMeaLnALRIudQo9V-dTKGc",
  authDomain: "carixin.firebaseapp.com",
  projectId: "carixin",
  storageBucket: "carixin.appspot.com",
  messagingSenderId: "880269819540",
  appId: "1:880269819540:web:c2cc35dc6f2e66e6fc3e81",
  measurementId: "G-CLNKWHY087",
};

// Initialize Firebase
firebase.default.initializeApp(firebaseConfig);
firebase.default.analytics();

const messaging = firebase.default.messaging();

messaging
  .getToken()
  .then((token) => {
    console.log(token);
  })
  .catch((err) => {
    console.log(err);
  });

const auth = firebase.default.auth();
const storage = firebase.default.storage();

export { auth, storage, firebase, messaging };
