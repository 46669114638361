<template>
  <div class="card text-center">
    <h1 class=" card-header bg-primary text-header text-white">
      Notification
    </h1>
    <div class="card-body">
      <!-- Button trigger modal notification -->
      <button
        type="button"
        class="btn btn-primary btn-sm float-right"
        data-toggle="modal"
        data-target="#notification_modal"
        @click="resetForm()"
      >
        Send Notification
      </button>

      <div class="clearfix"></div>

      <table class="table table-inverse table-striped" id="notifications">
        <thead>
          <tr>
            <th>#</th>
            <th>title</th>
            <th>body</th>
            <th>topic</th>
            <th>Sent at</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody v-if="notifications.length > 0">
          <tr
            v-for="(notification, index) in notifications"
            :key="notification._id"
          >
            <td scope="row">{{ index }}</td>
            <td>{{ notification.title }}</td>
            <td>{{ notification.body }}</td>
            <td>{{ notification.topic }}</td>
            <td>{{ notification.createdAt }}</td>
            <td>
              <button
                type="button"
                class="btn btn-primary btn-sm tetx-white"
                @click="resendNotif(notification._id)"
              >
                <i class="fa fa-share" aria-hidden="true"></i>
              </button>
            </td>
            <td>
              <button
                type="button"
                class="btn btn-danger btn-sm tetx-white"
                @click="deleteNotif(notification._id)"
              >
                <i class="fa fa-trash" aria-hidden="true"></i>
              </button>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-if="!loading">
            <td colspan="5" class="text-center">No item available</td>
          </tr>
          <tr v-else>
            <td colspan="5" class="text-center">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- Modal Notification -->
      <div
        class="modal fade"
        id="notification_modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="notification_modal"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Send Notification</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <!-- title -->
              <div class="md-form mt-3">
                <input
                  type="text"
                  id="notification_title"
                  class="form-control"
                  v-model.trim="title"
                />
                <label class="form-label active" for="notification_title"
                  >Notification title</label
                >
              </div>
              <!--body-->
              <div class="md-form">
                <textarea
                  id="notification_body"
                  class="form-control md-textarea"
                  rows="3"
                  v-model.trim="body"
                ></textarea>
                <label class="active" for="notification_body"
                  >Notificaiton body</label
                >
              </div>
              <!-- topic -->
              <div class="form-group">
                <label for="topic">Topic</label>
                <select class="form-control" id="topic" v-model="topic">
                  <option value="Choose a topic" selected
                    >Choose a topic</option
                  >
                  <option value="reminders">Reminders</option>
                  <option value="offers">Offers</option>
                  <option value="updates">Updates</option>
                </select>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="sendNotifToTopic()"
                :disabled="disabled"
              >
                Send and Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import swal from "sweetalert";

export default {
  data() {
    return {
      notifications: [],
      title: null,
      body: null,
      topic: "Choose a topic",
      disabled: false,
      loading: true,
    };
  },
  methods: {
    fetchNotifications() {
      axios
        .get("/api/notifications")
        .then((response) => {
          this.notifications = response.data[1]["data"];
          this.loading = false;
          if (typeof window.$.fn.DataTable != "undefined") {
            window.$(document).ready(function() {
              window.$("#notifications").DataTable();
            });
          }
        })
        .catch((error) => {
          swal("Oops", error.toString(), "error");
        });
    },
    resendNotif(notifId) {
      axios
        .get("/api/notifications/"+notifId)
        .then((response) => {
            this.title= response.data["title"]
            this.body= response.data["body"]
            this.topic=response.data["topic"]
            this.sendNotifToTopic()          
        })
        .catch((error) => {
          swal("Oops", error.toString(), "error");
        });
    },
    sendNotifToTopic() {
      if (this.title && this.body && this.topic != "Choose a topic") {
        this.disabled = true;
        axios
          .post("/api/notifications/topic", {
            title: this.title,
            body: this.body,
            topic: this.topic,
          })
          .then((response) => {
            let result = {
              _id: response.data.data["_id"],
              title: response.data.data["title"],
              body: response.data.data["body"],
              topic: response.data.data["topic"],
              createdAt: response.data.data["createdAt"],
            };
            this.notifications.unshift(result);
            swal({
              title: "Good job!",
              text: "Done successfully ",
              icon: "success",
            });
          })
          .catch((error) => {
            swal("Oops", error.toString(), "error");
          });
      } else {
        swal("Oops", "all fields are required", "error");
      }
    },
    deleteNotif(id) {
      var self = this;
      swal({
        title: "Are you sure?",
        text:
          "Once deleted, you will not be able to recover this imaginary file!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          axios
            .delete("/api/notifications/" + id)
            .then(() => {
              self.notifications = self.notifications.filter(
                (e) => e["_id"] != id
              );
              swal("Poof! the notification has been deleted!", {
                icon: "success",
              });
            })
            .catch(function(error) {
              console.log(error);
            });
        } else {
          swal("Your service is safe!");
        }
      });
    },
    resetForm() {
      this.disabled = false;
      this.title = null;
      this.body = null;
      this.topic = "Choose a topic";
    },
  },
  mounted() {
    this.fetchNotifications();
  },
};
</script>
