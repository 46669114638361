<template>
  <div id="user-profile">
      <div class="alert alert-warning" role="alert" v-if="!user.emailVerified">
        <strong>Email verification!</strong> You should verify your email to unlock other capablities
        <span
          class="alert-link cursor-pointer"
          @click="resendEmailVerification()"
          >resend email verification</span
        >
      </div>
      <!-- Card -->
      <div class="card testimonial-card pt-4">
        <!-- Background color -->
        <div class="card-up indigo lighten-1"></div>

        <!-- Avatar -->
        <div
          class="avatar mx-auto white text-info"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            height: 200px;
            width: 200px;
            overflow: hidden;
            border-radius: 50%;
          "
        >
          <label calss="profi-lab d-block" for="profi" style="cursor: pointer">
            <input type="file" class="d-none" />
            <img
              :src="profile"
              class="rounded-circle"
              alt="avatar"
              style="width: 200px"
            />
            <i class="fas fa-pencil-alt" aria-hidden="true"></i>
          </label>
        </div>
        <div class="d-flex justify-center">
          <input
            class="btn btn-primary text-center btn-rounded mx-auto"
            type="button"
            value="save"
          />
        </div>

        <!-- Content -->
        <div class="card-body">
          <!-- Name -->
          <h4 class="card-title">{{ fullname }}</h4>
          <hr />

          <div class="modifWrapper row">
            <!-- Material input -->
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-6">
                  <div class="md-form ml-0 mr-0">
                    <input
                      id="firstname"
                      type="text"
                      v-model.trim="firstname"
                      class="form-control ml-0"
                    />
                    <label for="firstname" class="active">Firstname</label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="md-form ml-0 mr-0">
                    <input
                      id="lastname"
                      type="text"
                      v-model.trim="lastname"
                      class="form-control ml-0"
                    />
                    <label for="lastname" class="active">Lastname</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 m-auto">
              <save-button
                @callFunc="updateDisplayName()"
                ref="saveButton"
              ></save-button>
            </div>
            <div class="col-md-8">
              <div class="md-form ml-0 mr-0">
                <input
                  id="email"
                  type="email"
                  v-model.trim="email"
                  class="form-control ml-0"
                />
                <label for="email" class="active">Email</label>
              </div>
            </div>
            <div class="col-md-4 m-auto">
              <save-button
                data-toggle="modal"
                data-target="#modalLoginAvatar4"
                @callFunc="updateAction('update_email')"
                ref="saveButton"
              ></save-button>
            </div>
            <div class="col-md-8">
              <div class="md-form mt-2">
                <input
                  class="form-control"
                  type="date"
                  id="dateNaissProfile"
                  v-model="birthday"
                />
                <label for="dateNaissProfile" class="active">date of birth</label>
              </div>
            </div>
            <div class="col-md-4 m-auto">
              <save-button
                @callFunc="updateField('birthday', birthday)"
                ref="saveButton"
              ></save-button>
            </div>
            <!-- <div class="col-md-4 m-auto">
                <button class="btn btn-primary btn-rounded btn-block">
                  enregistrer<i
                    class="ml-2 fas fa-pencil-alt"
                    aria-hidden="true"
                  ></i>
                </button>
              </div> -->

            <div class="col-md-8">
              <div class="md-form ml-0 mr-0">
                <input
                  id="address"
                  type="text"
                  v-model.trim="address"
                  class="form-control ml-0"
                />
                <label for="address" class="active">Address</label>
              </div>
            </div>
            <div class="col-md-4 m-auto">
              <save-button
                @callFunc="updateField('address', address)"
                ref="saveButton"
              ></save-button>
            </div>
            <div class="col-md-8">
              <div class="md-form ml-0 mr-0">
                <input
                  id="phone_number"
                  type="text"
                  v-model.trim="phone_number"
                  class="form-control ml-0"
                />
                <label for="phone_number" class="active">Phone number</label>
              </div>
            </div>
            <div class="col-md-4 m-auto">
              <save-button
                @callFunc="updateField('phone_number', phone_number)"
                ref="saveButton"
              ></save-button>
            </div>

            <div class="col-md-8">
              <div class="md-form">
                <i class="fas fa-pencil-alt prefix active"></i>
                <textarea
                  class="md-textarea form-contro"
                  rows="3"
                  id="descProf"
                  v-model="bio"
                ></textarea>

                <label for="descProf" class="active">Bio</label>
              </div>
            </div>
            <div class="col-md-4 m-auto">
              <save-button @callFunc="updateField('bio', bio)"></save-button>
            </div>
          </div>

          <div class="row">
            <!-- change password -->
            <div class="col-md-12 mb-2">
              <button
                class="btn btn-primary btn-rounded btn-block my-2"
                data-toggle="modal"
                data-target="#modalLoginAvatar3"
              >
                Change password
                <i class="fa fa-key ml-2" aria-hidden="true"></i>
              </button>
            </div>

            <div class="col-md-12">
              <button
                class="btn btn-primary btn-rounded btn-block"
                data-toggle="modal"
                data-target="#modalLoginAvatar4"
                @click="updateAction('delete')"
              >
                Delete Account
                <i class="ml-2 fas fa-trash" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Card -->

    <!--Modal: pass change-->
    <div
      class="modal fade"
      id="modalLoginAvatar3"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog cascading-modal modal-avatar modal-sm"
        role="document"
      >
        <!--Content-->
        <div class="modal-content">
          <!--Header-->
          <div class="modal-header">
            <img
              :src="profile"
              alt="avatar"
              class="rounded-circle img-responsive"
            />
          </div>
          <!--Body-->
          <div class="modal-body text-center mb-1">
            <h5 class="mt-1 mb-2">Change password</h5>

            <div class="md-form ml-0 mr-0">
              <input
                id="oldPass"
                type="password"
                class="form-control form-control-sm ml-0"
                v-model="change_password.actualPassword"
              />
              <label for="oldPass" class="ml-0">Actual password</label>
            </div>
            <div class="md-form ml-0 mr-0">
              <input
                id="newPass"
                type="password"
                v-model="change_password.newPassword"
                class="form-control form-control-sm ml-0"
              />
              <label for="newPass" class="ml-0">New password</label>
            </div>

            <div class="text-center mt-4">
              <button @click="changePassword()" class="btn btn-primary mt-1">
                Confirm<i class="fa fa-sign-in ml-1"></i>
              </button>
            </div>
          </div>
        </div>
        <!--/.Content-->
      </div>
    </div>
    <!--Modal: pass change-->

    <!--Modal: supp compte-->
    <div
      class="modal fade"
      id="modalLoginAvatar4"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog cascading-modal modal-avatar modal-sm"
        role="document"
      >
        <!--Content-->
        <div class="modal-content">
          <!--Header-->
          <div class="modal-header">
            <img
              :src="profile"
              alt="avatar"
              class="rounded-circle img-responsive"
            />
          </div>
          <!--Body-->
          <div class="modal-body text-center mb-1">
            <h5 class="mt-1 mb-2 text-danger">{{ actionModelTitle }}</h5>

            <div class="md-form ml-0 mr-0">
              <input
                type="password"
                v-model="delete_password.password"
                class="form-control form-control-sm ml-0"
                id="passDel"
              />
              <label for="passDel" class="ml-0">Password</label>
            </div>

            <div class="text-center mt-4">
              <button class="btn btn-primary mt-1" @click="actionOnAccount()">
                Confirm<i class="fa fa-sign-in ml-1"></i>
              </button>
            </div>
          </div>
        </div>
        <!--/.Content-->
      </div>
    </div>
    <!--Modal: sup compte-->
  </div>
</template>

<script>
const { auth } = require("@/firebase");
const axios = require("axios");
import swal from "sweetalert";
import SaveButton from "./partiels/SaveButton.vue";

export default {
  components: { SaveButton },
  name: "UserProfile",
  data() {
    return {
      user: null,
      firstname: "",
      lastname: "",
      profile: "",
      email: "",
      password: "",
      address: "",
      birthday: "",
      phone_number: "",
      ssn: "",
      bio: "",
      change_password: {
        actualPassword: "",
        newPassword: "",
      },
      delete_password: {
        password: "",
      },
      loading: false,
      action_on_field: "",
    };
  },
  computed: {
    fullname() {
      return this.firstname + " " + this.lastname;
    },
    actionModelTitle() {
      return this.action_on_field == "delete"
        ? "Delete account !"
        : "Update Email";
    },
  },
  created() {
    this.user = auth.currentUser;
  },
  mounted() {
    axios.get(`/api/users/${this.user.uid}`).then((user) => {
      let currentUser = user["data"];
      this.firstname = currentUser.firstname;
      this.profile = currentUser.profile;
      this.lastname = currentUser.lastname;
      this.email = currentUser.email;
      this.address = currentUser.address;
      this.birthday = currentUser.birthday;
      this.phone_number = currentUser.phone_number;
      this.ssn = currentUser.ssn;
      this.bio = currentUser.bio;
    });
  },
  methods: {
    changePassword() {
      auth
        .signInWithEmailAndPassword(
          this.user.email,
          this.change_password.actualPassword
        )
        .then((userCredentiels) => {
          userCredentiels.user
            .updatePassword(this.change_password.newPassword)
            .then(() => {
              swal("Success", "Password changed successfully", "success");
            })
            .catch((error) => {
              swal("Oops", error.toString(), "error");
            });
        })
        .catch((error) => {
          swal("Oops", error.toString(), "error");
        });
    },
    actionOnAccount() {
      this.setLoading(true);
      auth
        .signInWithEmailAndPassword(
          this.user.email,
          this.delete_password.password
        )
        .then((userCredentiels) => {
          if (this.action_on_field == "delete") {
            userCredentiels.user
              .delete()
              .then(() => {
                this.setLoading(false);
                swal("Success", "Account deleted successfully", "success");
              })
              .catch((error) => {
                this.setLoading(false);

                swal("Oops", error.toString(), "error");
              });
          }
          if (this.action_on_field == "update_email") {
            let newData = [
              {
                propName: "email",
                value: this.email,
              },
            ];
            axios
              .patch(`/api/users/${this.user.uid}`, newData)
              .then(() => {
                auth
                  .signInWithEmailAndPassword(
                    this.user.email,
                    this.delete_password.password
                  )
                  .then((userCredentiels) => {
                    this.setLoading(false);

                    userCredentiels.user.updateEmail(this.email).then(() => {
                      swal({
                        title: "Good job!",
                        text: "Email updated successfully",
                        icon: "success",
                      });
                    });
                  });
              })
              .catch((error) => {
                this.setLoading(false);

                swal("Oops", error.toString(), "error");
              });
          }
        })
        .catch((error) => {
          this.setLoading(false);
          swal("Oops", error.toString(), "error");
        });
    },
    updateDisplayName() {
      this.setLoading(true);
      let newData = [
        {
          propName: "firstname",
          value: this.firstname,
        },
        {
          propName: "lastname",
          value: this.lastname,
        },
      ];
      axios
        .patch(`/api/users/${this.user.uid}`, newData)
        .then(() => {
          this.user
            .updateProfile({
              displayName: this.firstname + " " + this.lastname,
            })
            .then(() => {
              this.setLoading(false);
              swal({
                title: "Good job!",
                text: "FullName updated successfully",
                icon: "success",
              });
            })
            .catch((error) => {
              swal("Oops", error.toString(), "error");
            });
        })
        .catch((error) => {
          swal("Oops", error.toString(), "error");
        });
    },
    updateField(field, value) {
      this.setLoading(true);
      let newData = [
        {
          propName: field,
          value: value,
        },
      ];
      axios
        .patch(`/api/users/${this.user.uid}`, newData)
        .then(() => {
          this.setLoading(false);
          swal({
            title: "Good job!",
            text: `${field} updated successfully`,
            icon: "success",
          });
        })
        .catch((error) => {
          swal("Oops", error.toString(), "error");
        });
    },
    updatePhoneNumber() {
      this.setLoading(true);
      let newData = [
        {
          propName: "phone_number",
          value: this.phone_number,
        },
      ];
      axios
        .patch(`/api/users/${this.user.uid}`, newData)
        .then(() => {
          this.user
            .updateProfile({
              phoneNumber: this.phone_number,
            })
            .then(() => {
              this.setLoading(false);
              swal({
                title: "Good job!",
                text: "PhoneNumber updated successfully",
                icon: "success",
              });
            })
            .catch((error) => {
              swal("Oops", error.toString(), "error");
            });
        })
        .catch((error) => {
          swal("Oops", error.toString(), "error");
        });
    },
    setLoading(loading) {
      this.loading = loading;
    },
    updateAction(action) {
      this.action_on_field = action;
    },
    resendEmailVerification() {
      auth.currentUser.sendEmailVerification().then(() => {
        swal(
          "Success",
          "Email sent successfully (check your email box)",
          "success"
        );
      });
    },
  },
};
</script>
<style lang="scss">
.testimonial-card {
  .card-body h4 {
    text-align: center;
  }
  .avatar {
    position: relative;
    text-shadow: 0px 0px 11px black;
    i {
      position: absolute;
      font-size: 3em;
      bottom: 0;
      position: absolute;
      right: 25px;
    }
  }
}
</style>
