<template>
  <div id="login">
    <div class="row">
      <div class="col-md-6 d-none d-md-block">
        <div class="pic_login">
          <div class="b-center">
            <img
              src="../../assets/images/login_pic.svg"
              class="img-fluid w-75"
              alt="sign in"
            />
            <p class="p-4 text-white text-center">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Laboriosam, fugiat repellat magnam nobis debitis soluta. Lorem
              ipsum dolor sit, amet consectetur adipisicing elit. Dolorum,
              reiciendis.
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="b-center">
          <div class="container p-4">
            <div class="card">
              <div class="card-body">
                <h2 class="font-weight-bold text-primary">Sign in</h2>
                <login-user />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginUser from "../../components/LoginUser.vue";
export default {
  components: { LoginUser },
};
</script>
<style lang="scss">
.pic_login {
  height: calc(100vh - 64px);
  background-color: #000de9;
  border-radius: 0 0 60% 0;
  padding: 0 60px;
}
.b-center {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
