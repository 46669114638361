<template>
  <div class="">
    <div class="card border border-primary mx-auto mt-5 container">
      <div class="card-body">
        <h2 id="-privacy-policy-" class="text-primary text-uppercase">
          <strong>Privacy Policy</strong>
        </h2>
        <h3 class="text-primary text-uppercase" id="-introduction-">
          <strong>Introduction</strong>
        </h3>
        <p>
          Welcome to CARIXIN (&quot;we,&quot; &quot;us,&quot; &quot;our&quot;).
          This Privacy Policy explains how we collect, use, disclose, and
          safeguard your information when you use our mobile application
          (&quot;App&quot;) that connects users with mechanics. Your privacy is
          of utmost importance to us, and we are committed to protecting the
          personal information you provide while using our services.
        </p>
        <p>
          By using the App, you agree to the terms and practices outlined in
          this Privacy Policy. If you do not agree, please refrain from using
          the App.
        </p>
        <h3 class="text-primary text-uppercase" id="-information-we-collect-">
          <strong>Information We Collect</strong>
        </h3>
        <p>
          We collect both personal and non-personal information from users
          (customers) and mechanics in order to provide the services offered
          through the App. This information is collected directly from you when
          you register and use the App.
        </p>
        <h4 id="-for-customers-users-">
          <strong>For Customers (Users):</strong>
        </h4>
        <ol>
          <li>
            <p><strong>Personal Information:</strong></p>
            <ul>
              <li>Name</li>
              <li>Email address</li>
              <li>Phone number</li>
              <li>Location data (when the app is used to request services)</li>
              <li>
                Payment details (credit/debit card information, billing details)
              </li>
            </ul>
          </li>
          <li>
            <p><strong>Non-Personal Information:</strong></p>
            <ul>
              <li>
                Usage data (app interactions, pages visited, session duration)
              </li>
              <li>
                Device and technical data (IP address, operating system, device
                model)
              </li>
            </ul>
          </li>
        </ol>
        <h4 id="-for-mechanics-"><strong>For Mechanics:</strong></h4>
        <ol>
          <li>
            <p><strong>Personal Information:</strong></p>
            <ul>
              <li>Name</li>
              <li>Email address</li>
              <li>Phone number</li>
              <li>Address</li>
              <li>
                Vehicle details (if necessary for the mechanic’s services)
              </li>
              <li>Professional qualifications and certifications</li>
              <li>
                National ID or other government-issued identification (for
                verification purposes)
              </li>
              <li>Bank account information (for payment processing)</li>
              <li>Location data (to match with customer requests)</li>
            </ul>
          </li>
          <li>
            <p><strong>Non-Personal Information:</strong></p>
            <ul>
              <li>Usage data (app interactions, bookings, session duration)</li>
              <li>
                Device and technical data (IP address, operating system, device
                model)
              </li>
            </ul>
          </li>
        </ol>
        <h3
          class="text-primary text-uppercase"
          id="-how-we-use-your-information-"
        >
          <strong>How We Use Your Information</strong>
        </h3>
        <p>We use the information collected to:</p>
        <ul>
          <li>
            Connect customers with mechanics based on their location and needs.
          </li>
          <li>
            Facilitate payments between customers and mechanics through the app.
          </li>
          <li>
            Verify the identity and qualifications of mechanics to ensure high
            service quality.
          </li>
          <li>
            Send messages between customers and mechanics for communication
            about services.
          </li>
          <li>
            Improve our app performance, user experience, and develop new
            features.
          </li>
          <li>Respond to your queries, requests, and complaints.</li>
          <li>Comply with legal obligations and regulatory requirements.</li>
        </ul>
        <h3
          class="text-primary text-uppercase"
          id="-how-we-share-your-information-"
        >
          <strong>How We Share Your Information</strong>
        </h3>
        <p>
          We do <strong>not</strong> sell or share your personal information
          with third parties for marketing or any unrelated purposes.
        </p>
        <p>
          Your personal information may only be shared in the following
          circumstances:
        </p>
        <ol>
          <li>
            <p>
              <strong>Service Providers:</strong> We may share your information
              with third-party vendors who assist in payment processing,
              identity verification, data analytics, or customer support. These
              vendors are bound by confidentiality agreements and only use your
              information as instructed by us.
            </p>
          </li>
          <li>
            <p>
              <strong>Legal Compliance:</strong> We may disclose your personal
              information if required to do so by law, legal process, or
              government request.
            </p>
          </li>
          <li>
            <p>
              <strong>Business Transfers:</strong> In the event of a merger,
              acquisition, or sale of assets, your information may be
              transferred as part of the business transaction, but you will be
              notified of any changes to how your data is handled.
            </p>
          </li>
        </ol>
        <h3
          class="text-primary text-uppercase"
          id="-how-we-protect-your-information-"
        >
          <strong>How We Protect Your Information</strong>
        </h3>
        <p>
          We take appropriate security measures to protect your personal data
          from unauthorized access, alteration, disclosure, or destruction.
          These measures include:
        </p>
        <ul>
          <li>
            Encryption of sensitive data such as payment and identity
            information.
          </li>
          <li>Secure servers and data storage.</li>
          <li>
            Access control protocols that limit access to your information to
            only authorized personnel.
          </li>
          <li>Regular monitoring and updates of security protocols.</li>
        </ul>
        <p>
          However, please understand that no method of transmission over the
          internet or electronic storage is 100% secure. While we strive to
          protect your data, we cannot guarantee its absolute security.
        </p>
        <h3 class="text-primary text-uppercase" id="-data-retention-">
          <strong>Data Retention</strong>
        </h3>
        <p>
          We retain your personal data for as long as necessary to fulfill the
          purposes outlined in this Privacy Policy or as required by law. If you
          request account deletion, we will remove your personal information
          unless there is a legal obligation to retain it.
        </p>
        <h3 class="text-primary text-uppercase" id="-your-privacy-rights-">
          <strong>Your Privacy Rights</strong>
        </h3>
        <p>
          Depending on your location, you may have certain rights regarding your
          personal data, including:
        </p>
        <ul>
          <li>
            <strong>Access:</strong> You can request access to the information
            we hold about you.
          </li>
          <li>
            <strong>Correction:</strong> You may request the correction of any
            inaccurate or incomplete data.
          </li>
          <li>
            <strong>Deletion:</strong> You may request the deletion of your
            personal data, subject to certain legal exceptions.
          </li>
          <li>
            <strong>Objection or Restriction:</strong> You may object to or
            request a restriction on the processing of your data in certain
            circumstances.
          </li>
        </ul>
        <p>
          To exercise any of these rights, please contact us at
          contact@bachirsouldi.com.
        </p>
        <h3 class="text-primary text-uppercase" id="-children-s-privacy-">
          <strong>Children’s Privacy</strong>
        </h3>
        <p>
          Our services are not intended for children under 16 years of age. We
          do not knowingly collect personal information from children. If you
          believe we have collected data from a child, please contact us so we
          can take the appropriate steps to remove that information.
        </p>
        <h3
          class="text-primary text-uppercase"
          id="-changes-to-this-privacy-policy-"
        >
          <strong>Changes to This Privacy Policy</strong>
        </h3>
        <p>
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or legal requirements. You will be notified of any
          material changes through the App or via email. Your continued use of
          the App after any changes indicate your acceptance of the updated
          policy.
        </p>
        <h3 class="text-primary text-uppercase" id="-data-retention-">
          <strong> Delete my account </strong>
        </h3>
        <p>
          If you want to delete your account, you can find more information on
          <router-link class="nav-link waves-effect" to="/delete-my-account"
            >Delete my account</router-link
          >
        </p>
        <h3 class="text-primary text-uppercase" id="-contact-us-">
          <strong>Contact Us</strong>
        </h3>
        <p>
          If you have any questions or concerns about this Privacy Policy,
          please contact us at:
        </p>
        <ul>
          <li>Carixin llc</li>
          <!-- <li>contact@carixin.com</li> -->
          <li>+213541229487</li>
          <li>contact@bachirsouldi.com</li>
        </ul>
        <hr />
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../components/partiels/Footer.vue";

export default {
  components: {
    Footer,
  },
};
</script>
