<template>
  <div id="how-it-works">
    <div class="card border border-primary mx-auto mt-5 container">
      <div class="card-body">
        <h2 id="-how-it-works-" class="text-primary text-uppercase"><strong>How It Works</strong></h2>
        <p>
          <strong>Carixin</strong> makes it easy for customers to connect with
          qualified mechanics for vehicle repair and maintenance services.
          Here&#39;s a step-by-step guide on how the app works for both
          customers and mechanics:
        </p>
        <h3 id="-for-customers-"><strong>For Customers</strong></h3>
        <ol>
          <li>
            <p><strong>Download and Sign Up</strong></p>
            <ul>
              <li>
                Download the <strong>Carixin</strong> app from the App Store or
                Google Play Store.
              </li>
              <li>
                Sign up with your name, phone number, email, and payment details
                to create an account.
              </li>
            </ul>
          </li>
          <li>
            <p><strong>Request a Service</strong></p>
            <ul>
              <li>
                Enter your location manually or allow the app to use your GPS to
                detect your location.
              </li>
              <li>
                Select the type of service you need (e.g., oil change, tire
                repair, engine check).
              </li>
              <li>
                You’ll see a list of available mechanics near you, along with
                their ratings, experience, and price estimates.
              </li>
            </ul>
          </li>
          <li>
            <p><strong>Choose a Mechanic</strong></p>
            <ul>
              <li>
                Browse through mechanic profiles to review their qualifications,
                experience, and customer ratings.
              </li>
              <li>
                Select the mechanic you prefer based on availability and
                pricing.
              </li>
            </ul>
          </li>
          <li>
            <p><strong>Confirm the Booking</strong></p>
            <ul>
              <li>Once you choose a mechanic, confirm the service request.</li>
              <li>
                You can communicate with the mechanic via the in-app messaging
                feature to discuss details or ask questions.
              </li>
            </ul>
          </li>
          <li>
            <p><strong>Track Your Mechanic</strong></p>
            <ul>
              <li>
                After confirming the booking, you can track the mechanic’s
                arrival in real time.
              </li>
              <li>
                The mechanic will arrive at your location to provide the
                requested service.
              </li>
            </ul>
          </li>
          <li>
            <p><strong>Payment</strong></p>
            <ul>
              <li>
                After the service is completed, payment will be automatically
                processed through the app.
              </li>
              <li>
                You can rate the mechanic and provide feedback on the service.
              </li>
            </ul>
          </li>
        </ol>
        <h3 id="-for-mechanics-"><strong>For Mechanics</strong></h3>
        <ol>
          <li>
            <p><strong>Sign Up and Get Verified</strong></p>
            <ul>
              <li>
                Download the <strong>Carixin</strong> app and sign up as a
                mechanic.
              </li>
              <li>
                Provide your personal information, professional qualifications,
                and identification documents.
              </li>
              <li>
                Your profile will be reviewed, and upon approval, you’ll be
                added to our network of mechanics.
              </li>
            </ul>
          </li>
          <li>
            <p><strong>Set Your Availability</strong></p>
            <ul>
              <li>You can set your working hours and the areas you cover.</li>
              <li>
                Adjust your availability in real time as needed to start
                accepting job requests.
              </li>
            </ul>
          </li>
          <li>
            <p><strong>Accept Requests</strong></p>
            <ul>
              <li>
                You’ll receive notifications when a nearby customer requests a
                service that matches your skillset.
              </li>
              <li>
                Review the job details, estimated payment, and location, then
                accept or decline the request.
              </li>
            </ul>
          </li>
          <li>
            <p><strong>Perform the Service</strong></p>
            <ul>
              <li>
                Once you accept the request, navigate to the customer’s location
                using the app’s GPS feature.
              </li>
              <li>
                Communicate with the customer through the in-app messaging
                feature to clarify any details.
              </li>
              <li>Complete the service to the customer’s satisfaction.</li>
            </ul>
          </li>
          <li>
            <p><strong>Get Paid</strong></p>
            <ul>
              <li>
                Once the service is completed, payment will be processed
                directly through the app to your bank account.
              </li>
              <li>
                Customers can rate and review your service, helping you build
                your reputation within the app.
              </li>
            </ul>
          </li>
        </ol>
        <hr />
        <h3 id="-why-use-app-name-">
          <strong>Why Use <strong>Carixin</strong>?</strong>
        </h3>
        <ul>
          <li>
            <p><strong>For Customers:</strong></p>
            <ul>
              <li>Convenient on-demand access to certified mechanics.</li>
              <li>Transparent pricing and ratings.</li>
              <li>Easy, secure payment processing.</li>
              <li>Real-time mechanic tracking and communication.</li>
            </ul>
          </li>
          <li>
            <p><strong>For Mechanics:</strong></p>
            <ul>
              <li>
                Access to a broad customer base with no need for marketing.
              </li>
              <li>Flexible working hours – you choose when to work.</li>
              <li>
                Immediate job offers based on your expertise and location.
              </li>
              <li>Fast, secure payments directly to your account.</li>
            </ul>
          </li>
        </ul>
        <p>
          <strong>Carixin</strong> simplifies the process of finding reliable
          mechanics for your vehicle, whether you’re in need of routine
          maintenance or an urgent repair. Join today and experience hassle-free
          auto service at your convenience!
        </p>
        <hr />
        <p>
          This section provides a clear and easy-to-understand explanation of
          how both customers and mechanics can use the app. You can customize
          the app name and adjust features based on what your app offers.
        </p>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../components/partiels/Footer.vue";

export default {
  components: {
    Footer,
  },
};
</script>
