import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import HowItWorks from "../views/HowItWorks.vue";
import Login from "../views/auth/Login.vue";
import Dashboard from "../views/Dashboard.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import HomeDashboard from "../components/dashboard/HomeDashboard";
import Shops from "../views/Shops.vue";
import Services from "../views/Services.vue";
import Orders from "../views/Orders.vue";
import Users from "../views/Users.vue";
import Notification from "../views/Notification.vue";
import Messages from "../components/dashboard/Messages";
import UserProfile from "../components/dashboard/UserProfile";
import MessagesHolder from "@/components/dashboard/partiels/MessagesHolder";
import DeleteMyAccount from '../views/DeleteMyAccount.vue'
import { auth } from "../firebase";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/how-does-carixin-work",
    name: "HowItWorks",
    component: HowItWorks,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    children: [
      {
        path: "/dashboard",
        component: HomeDashboard,
        name: "dashboard",
      },
      {
        path: "/profile",
        component: UserProfile,
        name: "profile",
      },
      {
        path: "/users",
        component: Users,
        name: "users",
      },
      {
        path: "/messages",
        component: Messages,
        name: "messages",

        children: [
          {
            path: "/messages/:userIdX/:userIdY",
            component: MessagesHolder,
          },
        ],
      },
      {
        path: "/shops",
        component: Shops,
        name: "shops",
      },
      {
        path: "/services",
        component: Services,
        name: "services",
      },
      {
        path: "/orders",
        component: Orders,
        name: "orders",
      },
      {
        path: "/notifications",
        component: Notification,
        name: "notifications",
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/privacy_policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy
  },
  {
    path: "/delete-my-account",
    name: "DeleteMyAccount",
    component: DeleteMyAccount
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);

  if (auth.currentUser && to.path == "/login") {
    next("/");
  }

  if (requiresAuth && !auth.currentUser) {
    next("/login");
  } else {
    next();
  }
});

export default router;
