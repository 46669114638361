<template>
  <div id="heading">
    <!-- Heading -->
    <div class="card my-4 wow fadeIn">
      <!--Card content-->
      <div class="card-body d-sm-flex justify-content-between">
        <h4 class="mb-2 mb-sm-0 pt-1">
          <router-link to="/">Home Page</router-link>
          <span> / </span>
          <!-- <span id="chemin">Tableau de bord</span> -->
          <span id="chemin"> {{ path }} </span>
        </h4>
        
        <!-- <form class="d-flex justify-content-center">
          <input
            type="search"
            placeholder="Rechercher"
            id="rechercheGlobal"
            aria-label="Search"
            class="form-control"
          />
          <button
            class="btn btn-primary btn-sm my-0 p"
            id="chercherBtn"
            type="submit"
          >
            <i class="fas fa-search"></i>
          </button>
        </form> -->
      </div>
    </div>
    <!-- Heading -->
  </div>
</template>
<script>
export default {
  name: "Heading",
  data() {
    return {
      path: "",
    };
  },
  watch: {
    $route(to) {
      this.path = to.name;
    },
  },
};
</script>
<style lang="scss" scoped>
#chemin {
  text-transform: capitalize;
}
</style>