import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { auth } from "@/firebase";
import router from "@/router";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: "",
    messages: [],
    token: "",
    error: null,
    loadingMessages :false
  },
  getters: {
    fullname: (state) => {
      return state.user ? state.user.firstname + " " + state.user.lastname : "Username";
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setMessages(state, messages) {
      state.messages = messages;
    },
    setToken(state, token) {
      state.token = token;
    },
    setError(state, error) {
      state.error = error;
    },
  },
  actions: {
    login(context, data) {
      axios
        .post("/api/users/login", {
          email: data.email,
          password: data.password,
        })
        .then((response) => {
          console.log(response.data);
          auth
            .signInWithEmailAndPassword(data.email, data.password)
            .then(async (userCredential) => {
              // var user = userCredential.user;
              const token = await auth.currentUser.getIdToken();
              context.commit("setToken", token);
              console.log(token);
              context.dispatch("fetchUser", userCredential);
              router.push("/dashboard");
            })
            .catch((error) => {
              var errorCode = error.code;
              var errorMessage = error.message;
              console.log(errorCode, errorMessage);
              context.commit("setError", errorMessage);
            });
        })
        .catch((err) => {
          console.log(err);
          context.commit("setError", err);
        });
    },
    fetchUser(context, data) {
      console.log("fetchUser", data);
      axios
        .get(`/api/users/${data.user.uid}`)
        .then((res) => {
          context.commit("setUser", res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getMessages(context, data) {
      console.log("store", data);
      axios
        .get(`/api/messages/user/${data[0]}/${data[1]}`)
        .then((response) => {
          context.commit("setMessages", response.data[1].data);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
  modules: {},
});
