<template>
  <div id="frame" class="py-5">
    <div id="sidepanel">
      <div id="profile">
        <div class="wrap">
          <img id="profile-img" :src="user.profile" class="online" alt="" />
          <p>{{ fullname }}</p>
          <i class="fa fa-chevron-down expand-button" aria-hidden="true"></i>
          <div id="status-options">
            <ul>
              <li id="status-online" class="active">
                <span class="status-circle"></span>
                <p>Online</p>
              </li>
              <li id="status-away">
                <span class="status-circle"></span>
                <p>Away</p>
              </li>
              <li id="status-busy">
                <span class="status-circle"></span>
                <p>Busy</p>
              </li>
              <li id="status-offline">
                <span class="status-circle"></span>
                <p>Offline</p>
              </li>
            </ul>
          </div>
          <div id="expanded">
            <label for="twitter"
              ><i class="fa fa-facebook fa-fw" aria-hidden="true"></i
            ></label>
            <input name="twitter" type="text" value="mikeross" />
            <label for="twitter"
              ><i class="fa fa-twitter fa-fw" aria-hidden="true"></i
            ></label>
            <input name="twitter" type="text" value="ross81" />
            <label for="twitter"
              ><i class="fa fa-instagram fa-fw" aria-hidden="true"></i
            ></label>
            <input name="twitter" type="text" value="mike.ross" />
          </div>
        </div>
      </div>
      <div id="search">
        <label for=""><i class="fa fa-search" aria-hidden="true"></i></label>
        <input type="text" placeholder="Search contacts..." v-model="search" />
      </div>
      <div id="contacts">
        <ul>
          <router-link
            tag="li"
            class="contact active"
            v-for="user in users"
            :key="user['_id']"
            :to="'/messages/' + user['_id'] + '/' + uid"
          >
            <div class="wrap">
              <span class="contact-status busy"></span>
              <img :src="user.profile" alt="" />
              <div class="meta">
                <p class="name">{{ user.firstname }} {{ user.lastname }}</p>
                <p class="preview">
                  {{ previewMessage(user.message) }}
                </p>
              </div>
            </div>
          </router-link>
        </ul>
        <!-- busy online away -->
      </div>
      <div id="bottom-bar">
        <button id="addcontact">
          <i class="fa fa-user-plus fa-fw" aria-hidden="true"></i>
          <span>Add contact</span>
        </button>
        <button id="settings">
          <i class="fa fa-cog fa-fw" aria-hidden="true"></i>
          <span>Settings</span>
        </button>
      </div>
    </div>
    <router-view />
    <!-- <MessagesHolder /> -->
  </div>
</template>

<script>
const axios = require("axios");
import { auth } from "@/firebase";
// import MessagesHolder from "@/components/dashboard/partiels/MessagesHolder";

export default {
  components: {
    // MessagesHolder,
  },
  data() {
    return {
      messages: null,
      users: null,
      typeMessage: "send",
      search: "",
      uid: "",
    };
  },

  methods: {
    previewMessage(message) {
      if (message) {
        return message[this.messages - 1];
      }
      return null;
    },

    fetchData() {
      axios
        .get("/api/users")
        .then((response) => {
          this.users = response.data[1].data.filter((e) => e._id != this.uid);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    fullname() {
      return this.$store.getters.fullname;
    },
  },
  watch: {
    search: function (val) {
      if (val.length) {
        this.users = this.users.filter(
          (e) => e.firstname.includes(val) || e.lastname.includes(val)
        );
      } else {
        this.fetchData();
      }
    },
  },
  mounted() {
    this.uid = auth.currentUser.uid;
    this.fetchData();
  },
};
</script>

<style scoped>
@import "../../assets/css/message_style.css";
</style>