<template>
  <div class="services">

      <div class="float-right">
        <button type="button" class="btn btn-success">
          <input
            type="file"
            ref="file"
            class="custom-file-input float-right position-absolute"
            id="services_csv"
            v-on:change="onFileLoad()"
          />
          Upload
        </button>
      </div>
      <div class="float-right">
        <button
          type="button"
          class="btn btn-primary waves-effect"
          data-toggle="modal"
          data-target="#serviceForm"
          @click="resetForm()"
        >
          <i class="fa fa-plus mr-2" aria-hidden="true"></i>Add Service
        </button>
      </div>
      <div class="clearfix"></div>

      <!-- Modal -->
      <div
        class="modal fade"
        id="serviceForm"
        tabindex="-1"
        role="dialog"
        aria-labelledby="modelTitleId"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Add Service</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form method="post" enctype="application/x-www-form-urlencoded">
              <div class="modal-body">
                <!-- service_name -->
                <div class="md-form mt-3">
                  <input
                    type="text"
                    id="service_name"
                    class="form-control"
                    v-model.trim="form.service_name"
                  />
                  <label :class="{ active: status }" for="service_name"
                    >Service's name</label
                  >
                </div>

                <!-- price -->
                <div class="md-form my-3">
                  <input
                    type="text"
                    id="service_price"
                    class="form-control"
                    v-model.trim="form.price"
                  />
                  <label :class="{ active: status }" for="service_price"
                    >Price</label
                  >
                </div>
                <!-- shop_price -->
                <div class="md-form my-3">
                  <input
                    type="text"
                    id="shop_price"
                    class="form-control"
                    v-model.trim="form.shop_price"
                  />
                  <label :class="{ active: status }" for="shop_price"
                    >Shop Price</label
                  >
                </div>
                <!-- dealer_price -->
                <div class="md-form my-3">
                  <input
                    type="text"
                    id="dealer_price"
                    class="form-control"
                    v-model.trim="form.dealer_price"
                  />
                  <label :class="{ active: status }" for="dealer_price"
                    >Dealer Price</label
                  >
                </div>

                <!-- time -->
                <div class="md-form my-3">
                  <input
                    type="text"
                    id="service_time"
                    class="form-control"
                    v-model.trim="form.time"
                  />
                  <label :class="{ active: status }" for="service_time"
                    >Time (min)</label
                  >
                </div>

                <!--details-->
                <div class="md-form">
                  <textarea
                    id="service_detail"
                    class="form-control md-textarea"
                    rows="3"
                    v-model.trim="form.details"
                  ></textarea>
                  <label :class="{ active: status }" for="service_detail"
                    >Details</label
                  >
                </div>

                <!-- save button -->
                <button
                  class="
                    btn btn-outline-info btn-rounded btn-block
                    z-depth-0
                    my-4
                    waves-effect
                  "
                  type="button"
                  @click.prevent="persistData(status, serviceId)"
                >
                  {{ saveStr }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- services card -->
      <div class="card  text-center">
        <div class="card-header bg-primary text-white">
          <h3>Services</h3>
        </div>
        <div class="card-body table-responsive">
          <table class="table" id="services">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Price</th>
                <th>Dealer price</th>
                <th>Shop price</th>
                <th>Time</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody v-if="services.length > 0">
              <tr v-for="(item, index) in services" :key="item._id">
                <td scope="row">{{ index + 1 }}</td>
                <td scope="row">{{ item.service_name }}</td>
                <td scope="row">{{ item.price }}</td>
                <td scope="row">{{ item.dealer_price }}</td>
                <td scope="row">{{ item.shop_price }}</td>
                <td scope="row">{{ item.time }} min</td>
                <td>
                  <button
                    type="button"
                    class="btn btn-primary btn-sm tetx-white"
                    data-toggle="modal"
                    data-target="#serviceForm"
                    @click="updateService(item._id, index)"
                  >
                    <i class="fa fa-pen" aria-hidden="true"></i>
                  </button>

                  <button
                    type="button"
                    class="btn btn-danger btn-sm tetx-white"
                    @click="deleteService(item._id)"
                  >
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  </button>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-if="!loading">
                <td colspan="4" class="text-center">No item available</td>
              </tr>
              <tr v-else>
                <td colspan="4" class="text-center">
                  <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
  
  </div>
</template>

<script>
const axios = require("axios");
import swal from "sweetalert";
import $ from "jquery";
import readXlsxFile from 'read-excel-file'


export default {
  data() {
    return {
      tag: "",
      tags: [],
      services: [],
      form: {
        service_name: "",
        price: "",
        dealer_price: "",
        shop_price: "",
        time: "",
        details: "",
      },
      loading: true,
      progress: 0,
      serviceId: 0,
      index: 0,
      saveStr: "Save",
      status: false,
      datatable: null,
      file: null,
    };
  },
  methods: {
    fetchData() {
      this.resetForm();
      let self = this;
      axios
        .get("/api/services?limit=1000")
        .then((response) => {
          this.services = response.data[1].data;
          this.loading = false;
          if (typeof $.fn.DataTable != "undefined") {
            $(document).ready(function() {
              self.datatable = $("#services").DataTable();
            });
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    fetchOneService(id) {
      // this.resetForm();
      axios
        .get("/api/services/" + id)
        .then((response) => {
          this.form.service_name = response.data.service_name;
          this.form.price = response.data.price;
          this.form.shop_price = response.data.shop_price;
          this.form.dealer_price = response.data.dealer_price;
          this.form.time = response.data.time;
          this.form.details = response.data.details;
          this.saveStr = "Update";
        })
        .catch(function(error) {
          swal("Oops", error.toString(), "error");
        });
    },
    persistData(status, id) {
      var self = this;

      if (!status) {
        this.services.unshift({
          service_name: this.form.service_name,
          dealer_price: this.form.dealer_price,
          shop_price: this.form.shop_price,
          price: this.form.price,
          time: this.form.time,
          details: this.form.details,
        });
        this.saveStr = "Saved";
        axios
          .post("/api/services", {
            service_name: this.form.service_name,
            price: this.form.price,
            shop_price: this.form.shop_price,
            dealer_price: this.form.dealer_price,
            time: this.form.time,
            details: this.form.details,
          })
          .then(function(response) {
            self.datatable.draw();
            swal({
              title: "Good job!",
              text: "Done successfully " + response,
              icon: "success",
            });
          })
          .catch(function(error) {
            swal("Oops", error.toString(), "error");
          });
      } else {
        // update
        this.services[this.index].service_name = this.form.service_name;
        this.services[this.index].dealer_price = this.form.phone_number;
        this.services[this.index].shop_price = this.form.shop_price;
        this.services[this.index].price = this.form.price;
        this.services[this.index].time = this.form.time;
        this.services[this.index].details = this.form.details;

        axios
          .patch("/api/services/" + id, [
            {
              propName: "service_name",
              value: this.form.service_name,
            },
            {
              propName: "price",
              value: this.form.price,
            },
            {
              propName: "shop_price",
              value: this.form.shop_price,
            },
            {
              propName: "dealer_price",
              value: this.form.dealer_price,
            },
            {
              propName: "time",
              value: this.form.time,
            },
            {
              propName: "details",
              value: this.form.details,
            },
          ])
          .then((response) => {
            self.datatable.draw();
            console.log(response);
            this.saveStr = "Updated";
          })
          .catch(function(error) {
            console.log(error);
          });
      }
    },
    deleteService(id) {
      var self = this;
      swal({
        title: "Are you sure?",
        text:
          "Once deleted, you will not be able to recover this imaginary file!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          console.log(id);
          axios
            .delete("/api/services/" + id)
            .then(() => {
              self.datatable.draw();
              self.services = self.services.filter((e) => e["_id"] != id);
              swal("Poof! Your service has been deleted!", {
                icon: "success",
              });
            })
            .catch(function(error) {
              console.log(error);
            });
        } else {
          swal("Your service is safe!");
        }
      });
    },
    updateService(itemid, index) {
      this.status = true;
      this.serviceId = itemid;
      this.index = index;
      this.form.file = "";
      this.fetchOneService(itemid);
    },
    resetForm() {
      this.status = false;
      this.serviceId = 0;
      this.form = {
        service_name: "",
        price: "",
        dealer_price: "",
        shop_price: "",
        time: "",
        details: "",
      };
      this.saveStr = "Save";
    },
    onFileLoad() {
      const input = document.getElementById('services_csv')
      console.log(input.files[0]);

      readXlsxFile(input.files[0]).then((rows) => {
        // `rows` is an array of rows
        // each row being an array of cells.
          console.log(rows);     
      });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
