<template>
  <div class="shops">

      <div class="float-right">
        <button
          type="button"
          class="btn btn-primary waves-effect"
          data-toggle="modal"
          data-target="#shopForm"
          @click="resetForm()"
        >
          <i class="fa fa-plus mr-2" aria-hidden="true"></i>Add shops
        </button>
      </div>
      <div class="clearfix"></div>

      <!-- Modal -->
      <div
        class="modal fade"
        id="shopForm"
        tabindex="-1"
        role="dialog"
        aria-labelledby="modelTitleId"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Add Shops</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form method="post" enctype="application/x-www-form-urlencoded">
              <div class="modal-body">
                <!-- Name -->
                <div class="md-form mt-3">
                  <input
                    type="text"
                    id="shop_name"
                    class="form-control"
                    v-model.trim="form.name"
                  />
                  <label :class="{ active: status }" for="shop_name"
                    >Shop's name</label
                  >
                </div>

              <div v-if="form.thumbnail" class="my-2"> 
                <img :src="form.thumbnail" alt="Shop's showcase" class="img-thumbnail">
              </div>

                <!-- showcase -->
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="shop_showcase"
                      >Upload</span
                    >
                  </div>
                  <div class="custom-file">
                    <input
                      type="file"
                      class="custom-file-input"
                      id="inputGroupFile01"
                      ref="file"
                      aria-describedby="shop_showcase"
                      v-on:change="handleFileUpload()"
                    />
                    <label class="custom-file-label" for="inputGroupFile01"
                      >Choose Image</label
                    >
                  </div>
                </div>

                <!-- phone_number -->
                <div class="md-form my-3">
                  <input
                    type="text"
                    id="phone_number"
                    class="form-control"
                    v-model.trim="form.phone_number"
                  />
                  <label :class="{ active: status }" for="phone_number"
                    >Phone Number</label
                  >
                </div>

                <!--location-->
                <div class="md-form">
                  <textarea
                    id="shop_location"
                    class="form-control md-textarea"
                    rows="3"
                    v-model.trim="form.location"
                  ></textarea>
                  <label :class="{ active: status }" for="shop_location"
                    >Location</label
                  >
                </div>

                <!--details-->
                <div class="md-form">
                  <textarea
                    id="shop_detail"
                    class="form-control md-textarea"
                    rows="3"
                    v-model.trim="form.details"
                  ></textarea>
                  <label :class="{ active: status }" for="shop_detail"
                    >Details</label
                  >
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label :class="{ active: status }" for="hour_start"
                        >Time start</label
                      >
                      <input
                        type="time"
                        class="form-control"
                        v-model.trim="form.hour_start"
                        id="hour_start"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label :class="{ active: status }" for="hour_end"
                        >Time end</label
                      >
                      <input
                        type="time"
                        class="form-control"
                        v-model.trim="form.hour_end"
                        id="hour_end"
                      />
                    </div>
                  </div>
                </div>

                <div class="progress">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    :style="{ width: this.progress + '%' }"
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>

                <!-- Sign in button -->
                <button
                  class="
                    btn btn-outline-info btn-rounded btn-block
                    z-depth-0
                    my-4
                    waves-effect
                  "
                  type="button"
                  @click.prevent="persistData(status, shopId)"
                >
                  {{ saveStr }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="card text-center">
        <div class="card-header blue-gradient text-white ">
          <h1>Shops</h1>
        </div>
        <div class="card-body table-responsive">
          <table class="table" id="shops">
            <thead>
              <tr>
                <th>#</th>
                <th>name</th>
                <th>showcase</th>
                <th>location</th>
                <th>actions</th>
              </tr>
            </thead>
            <tbody v-if="shops.length > 0">
              <tr v-for="(item, index) in shops" :key="item._id">
                <td scope="row">{{ index + 1 }}</td>
                <td scope="row">{{ item.name }}</td>
                <td>
                  <img
                    :src="item.thumbnail"
                    class="img-fluid img-thumbnail"
                    style="width: 150px"
                    :alt="item.name"
                  />
                </td>
                <td>{{ item.meta.location }}</td>
                <td>
                  <button
                    type="button"
                    class="btn btn-primary btn-sm tetx-white"
                    data-toggle="modal"
                    data-target="#shopForm"
                    @click="updateShop(item._id, index)"
                  >
                    <i class="fa fa-pen" aria-hidden="true"></i>
                  </button>

                  <button
                    type="button"
                    class="btn btn-danger btn-sm tetx-white"
                    @click="deleteShop(item._id)"
                  >
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  </button>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-if="!loading">
                <td colspan="4" class="text-center">No item available</td>
              </tr>
              <tr v-else>
                <td colspan="4" class="text-center">
                  <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
 
  </div>
</template>

<script>
const axios = require("axios");
const { storage, firebase } = require("@/firebase");
const defaultFirebase = firebase.default;
// import $ from "jquery";

export default {
  data() {
    return {
      shops: [],
      form: {
        name: "",
        file: "",
        hour_start: "",
        hour_end: "",
        phone_number: "",
        location: "",
        details: "",
      },
      loading: true,
      progress: 0,
      shopId: 0,
      index: 0,
      saveStr: "Save",
      status: false,
      options: {
        headers: {
          "content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      },
    };
  },
  methods: {
    fetchData() {
      this.resetForm();
      axios
        .get("/api/shops", this.options)
        .then((response) => {
          console.log("shops", response.data[1].data);
          this.shops = response.data[1].data;
          this.loading = false;
          if (typeof window.$.fn.DataTable != "undefined") {
            window.$(document).ready(function() {
              window.$("#shops").DataTable();
            });
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    fetchOneShop(id) {
      // this.resetForm();
      axios
        .get("/api/shops/" + id)
        .then((response) => {
          console.log("fetched");
          this.form.name = response.data.name;
          this.form.thumbnail = response.data.thumbnail;
          this.form.phone_number = response.data.meta.phone_number;
          this.form.location = response.data.meta.location;
          this.form.hour_start = response.data.meta.hour_start;
          this.form.hour_end = response.data.meta.hour_end;
          this.form.details = response.data.details;
          this.saveStr = "Update";
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    persistData(status, id) {
      var storageRef = storage.ref();
      if (!status) {
        let uploadTask = storageRef
          .child("images/" + this.form.file.name)
          .put(this.form.file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.progress = progress;
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case defaultFirebase.storage.TaskState.PAUSED: // or 'paused'
                console.log("Upload is paused");
                break;
              case defaultFirebase.storage.TaskState.RUNNING: // or 'running'
                console.log("Upload is running");
                break;
            }
          },
          (error) => {
            // Handle unsuccessful uploads
            console.log(error);
          },
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              this.shops.unshift({
                name: this.form.name,
                thumbnail: downloadURL,
                meta: {
                  location: this.form.location,
                  hour_start: this.form.hour_start,
                  hour_end: this.form.hour_end,
                },
                details: this.form.details,
              });
              this.saveStr = "Saved";
              axios
                .post("/api/shops", {
                  name: this.form.name,
                  thumbnail: downloadURL,
                  meta: {
                    location: this.form.location,
                    hour_start: this.form.hour_start,
                    hour_end: this.form.hour_end,
                  },
                  details: this.form.details,
                })
                .then(function(response) {
                  console.log(response);
                })
                .catch(function(error) {
                  console.log(error);
                });
              console.log(downloadURL);
            });
          }
        );
      } else {
        // update
        console.log(this.shops[this.index]);
        this.shops[this.index].name = this.form.name;
        this.shops[this.index].phone_number = this.form.phone_number;
        this.shops[this.index].location = this.form.location;
        this.shops[this.index].details = this.form.details;
        this.shops[this.index].hour_start = this.form.hour_start;
        this.shops[this.index].hour_end = this.form.hour_end;
        if (this.form.file != "") {
          let uploadTask = storageRef
            .child("images/" + this.form.file.name)
            .put(this.form.file);
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              var progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              this.progress = progress;
              console.log("Upload is " + progress + "% done");
              switch (snapshot.state) {
                case defaultFirebase.storage.TaskState.PAUSED: // or 'paused'
                  console.log("Upload is paused");
                  break;
                case defaultFirebase.storage.TaskState.RUNNING: // or 'running'
                  console.log("Upload is running");
                  break;
              }
            },
            (error) => {
              // Handle unsuccessful uploads
              console.log(error);
            },
            () => {
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                axios
                  .patch("/api/shops/" + id, [
                    {
                      propName: "name",
                      value: this.form.name,
                    },
                    {
                      propName: "meta.phone_number",
                      value: this.form.phone_number,
                    },
                    {
                      propName: "meta.location",
                      value: this.form.location,
                    },
                    {
                      propName: "meta.hour_start",
                      value: this.form.hour_start,
                    },
                    {
                      propName: "meta.hour_end",
                      value: this.form.hour_end,
                    },
                    {
                      propName: "thumbnail",
                      value: downloadURL,
                    },
                  ])
                  .then((response) => {
                    this.saveStr = "Updated";
                    this.shops[this.index].thumbnail = downloadURL;
                    console.log(response);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              });
            }
          );
        } else {
          axios
            .patch("/api/shops/" + id, [
              {
                propName: "name",
                value: this.form.name,
              },
              {
                propName: "meta.phone_number",
                value: this.form.phone_number,
              },
              {
                propName: "meta.location",
                value: this.form.location,
              },
              {
                propName: "meta.hour_start",
                value: this.form.hour_start,
              },
              {
                propName: "meta.hour_end",
                value: this.form.hour_end,
              },
              {
                propName: "details",
                value: this.form.details,
              },
            ])
            .then((response) => {
              console.log(response);
              this.saveStr = "Updated";
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      }
    },
    handleFileUpload() {
      this.form.file = this.$refs.file.files[0];
    },
    deleteShop(id) {
      var self = this;
      var status = confirm("do you wanna really remove this item " + id);
      if (status) {
        axios
          .delete("/api/shops/" + id)
          .then((response) => {
            console.log(self.shops);
            self.shops = self.shops.filter((e) => e["_id"] != id);
            console.log(response);
          })
          .catch(function(error) {
            console.log(error);
          });
      }
      console.log(status);
    },
    updateShop(itemid, index) {
      this.status = true;
      this.shopId = itemid;
      this.index = index;
      this.form.file = "";
      this.fetchOneShop(itemid);
    },
    resetForm() {
      this.status = false;
      this.shopId = 0;
      this.form = {
        name: "",
        hour_start: "",
        hour_end: "",
        phone_number: "",
        location: "",
        file: "",
        details: "",
      };
      this.saveStr = "Save";
    },
  },
  mounted() {
    this.fetchData();

    if (typeof window.$.fn.DataTable != "undefined") {
      var self = this;
      window.$(document).ready(function() {
        if (self.shops.length > 0) {
          window.$("#shops").DataTable();
        }
      });
    }

    // var data = window.$("#shopstable").DataTable({
    //   ajax: {
    //     url: `/api/shops`,
    //     type: "get",
    //     dataSrc: "members", //my data is in an array called members
    //   },
    //   columns: [
    //     { bSearchable: false }, //col 1
    //     { bSearchable: true }, //col 2
    //     { bSearchable: false }, //col 3
    //     { bSearchable: true }, //col 5
    //     { bSearchable: false }, //col 6
    //   ],
    // });
    // data.draw();
  },
};
</script>
