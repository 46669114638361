<template>
  <button class="btn btn-primary btn-rounded btn-block" @click="callFunc()">
    <div class="spinner-border text-white" role="status" v-if="loading">
      <span class="sr-only">Loading...</span>
    </div>
    <div v-else>
      enregistrer<i class="ml-2 fas fa-pencil-alt" aria-hidden="true"></i>
    </div>
  </button>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
    };
  },

  methods: {
    callFunc() {
      this.setLoading(true);
      this.$emit("callFunc");
      setInterval(() => {
        this.setLoading(false);
      }, 500);
    },
    setLoading(loading) {
      this.loading = loading;
    },
  },
};
</script>
