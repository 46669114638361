<template>
  <nav class="navbar navbar-expand-md navbar-dark bg-dark fixed-top z-depth-0">
    <div class="container">
      <router-link
        class="navbar-brand bg-white p-1 rounded waves-effect"
        to="/"
      >
        <img src="../../assets/logo.png" alt="carixin" height="40" width="40" />
      </router-link>
      <button
        class="navbar-toggler d-lg-none"
        type="button"
        data-toggle="collapse"
        data-target="#colId"
        aria-controls="colId"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="colId">
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
          <li class="nav-item active">
            <router-link class="nav-link waves-effect" to="/">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link waves-effect"
              to="/how-does-carixin-work"
              >How it works</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link waves-effect"
              to="/privacy_policy"
              >Privacy policy</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link waves-effect" to="/about"
              >Services</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link waves-effect" to="/about"
              >Pricing</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link waves-effect" to="/about"
              >About</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="nav-link waves-effect" to="/dashboard"
              >Dashboard</router-link
            >
          </li>
        </ul>
        <ul class="navbar-nav ms-auto mt-2 mt-lg-0 justify-content-end">
          <li class="nav-item" v-if="!currentUser">
            <router-link class="nav-link waves-effect" to="/login"
              >Login</router-link
            >
          </li>
          <li class="nav-item avatar dropdown" v-else>
            <a
              class="nav-link waves-effect dropdown-toggle"
              id="navbarDropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                :src="user.profile"
                class="rounded-circle z-depth-0 mr-1"
                alt="profile image"
                style="width: 35px; height: 35px"
              />
              {{ fullname }}</a
            >
            <div
              class="dropdown-menu dropdown-primary"
              aria-labelledby="navbarDropdownMenuLink"
            >
              <router-link class="dropdown-item" to="/profile"
                >Profile</router-link
              >

              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#" @click="logout()">Logout</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { auth } from "@/firebase";

export default {
  data() {
    return {
      currentUser: null,
    };
  },
  methods: {
    logout() {
      auth
        .signOut()
        .then(() => {
          console.log("successfully logout");
          this.$router.push("/");
          // Sign-out successful.
        })
        .catch((error) => {
          console.log(error);
          // An error happened.
        });
    },
  },
  computed: {
    user() {
      console.log(this.$store.state);
      return this.$store.state.user;
    },
    fullname() {
      return this.$store.getters.fullname  ? this.$store.getters.fullname  : "user";
    },
  },
  mounted() {
    console.log(this.$store.getters);
    this.currentUser = auth.currentUser ? auth.currentUser : null;
    if (this.currentUser)
      this.$store.dispatch("fetchUser", { user: this.currentUser });
  },
};
</script>
