<template>
  <div class="content">
    <div class="contact-profile">
      <img :src="receiver.profile" alt="" />
      <p>{{ receiver.fullname }}</p>
      <div class="social-media">
        <i class="fa fa-facebook" aria-hidden="true"></i>
        <i class="fa fa-twitter" aria-hidden="true"></i>
        <i class="fa fa-instagram" aria-hidden="true"></i>
      </div>
    </div>
    <div class="messages">
      <div
        v-if="loading"
        class="
          d-flex
          justify-content-center
          align-items-center align-self-center
        "
      >
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <ul v-else-if="messages.length">
        <li v-if="more_message" class="text-center">
          <button
            type="button"
            class="btn btn-link text-primary"
            @click="load_more()"
          >
            more message
          </button>
        </li>
        <li
          v-for="message in messages"
          :key="message['_id']"
          :class="isSender(message.sender._id)"
        >
          <!-- <img :src="message.sender.profile" :alt="message.sender.firstname" /> -->
          <p>
            {{ message.message }}
          </p>
        </li>
        <!-- <li class="replies">
              <img src="http://emilcarlsson.se/assets/harveyspecter.png" alt="" />
              <p>
                When you're backed against the wall, break the god damn thing
                down.
              </p>
            </li> -->
      </ul>
      <ul class="text-center" v-else>
        <li>No messages</li>
      </ul>
    </div>
    <div class="message-input">
      <div class="wrap">
        <input
          type="text"
          v-model="my_message"
          v-on:keyup.enter="sendMessage()"
          placeholder="Write your message..."
        />
        <i class="fa fa-paperclip attachment" aria-hidden="true"></i>
        <button class="button" @click="sendMessage()">
          <i class="fa fa-paper-plane" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from "@/firebase";
import axios from "axios";
import $ from "jquery";
import swal from "sweetalert";

export default {
  data() {
    return {
      user: null,
      sender_uid: "",
      receiver_uid: "",
      my_message: "",
      receiver: {
        fullname: "",
        profile: "",
      },
      loading: false,
      more_message: false,
      messages: [],
      initial_page: 1,
    };
  },
  sockets: {
    connect: function() {
      console.log("socket connected");
    },
    receive_message: function(data) {
      // getMessaging.onBackgroundMessage((payload) => {
      //   console.log("Received background message ", payload);
      //   // Customize notification here
      //   const notificationTitle = "Background Message Title";
      //   const notificationOptions = {
      //     body: payload.message,
      //     icon: "https://firebasestorage.googleapis.com/v0/b/carixin.appspot.com/o/profile%2FuserProfile.png?alt=media&token=e2ac455e-5c19-42f5-8ca3-112115e2f66e",
      //   };

      //   self.registration.showNotification(
      //     notificationTitle,
      //     notificationOptions
      //   );
      // });
      if (
        // a voir apres
        (data.sender == this.sender_uid &&
          data.receiver.includes(this.receiver_uid)) ||
        (data.receiver.includes(this.sender_uid) &&
          data.sender == this.receiver_uid)
      ) {
        let message = data;
        message.sender = { _id: message.sender };
        this.messages.push(message);
        $(".messages").animate({ scrollTop: 20000000 }, "slow");
        this.initial_page = Math.floor(this.messages.length / 10) + 1;
      }
    },
  },
  methods: {
    goDown() {
      $(".messages").animate({ scrollTop: 20000000 }, "slow");
    },
    sendMessage() {
      // $socket is socket.io-client instance
      if (this.my_message.length > 0) {
        let message = {
          sender: this.sender_uid,
          receiver: [this.receiver_uid],
          message: this.my_message,
          title: "Carixin (Admin)",
          tokens: this.user.token,
          data: {
            type: "message",
          },
        };
        console.log("****message****",message);
        
        this.$socket.emit("send_message", message);
        this.my_message = "";
        this.goDown();
      }
    },
    isSender(id) {
      return id != auth.currentUser.uid ? "sent" : "replies";
    },
    fetchUser(uid) {
      axios
        .get(`/api/users/${uid}`)
        .then((res) => {
          this.user = res.data;
          this.receiver.fullname = res.data.firstname + " " + res.data.lastname;
          this.receiver.profile = res.data.profile;
        })
        .catch((err) => {
          swal("error", err);
          console.log(err);
        });
    },
    fetchMessages(receiver, sender) {
      axios
        .get(
          `/api/messages/user/${receiver}/${sender}?page=${this.initial_page}`
        )
        .then((response) => {
          console.log(response.data[1].data);

          if (response.data[1].data.length) {
            if (response.data[0].page * 10 >= response.data[0].total) {
              this.more_message = false;
            } else {
              this.more_message = true;
            }
            this.messages.unshift(...response.data[1].data.reverse());
          }
          this.loading = false;
          if (this.initial_page == 1) this.goDown();
        })
        .catch(function(error) {
          this.loading = false;
          console.log(error);
        });
    },
    load_more() {
      this.initial_page += 1;
      this.loading = true;
      console.log("load more", this.initial_page);
      this.fetchMessages(this.receiver_uid, this.sender_uid);
    },
  },
  watch: {
    $route(to) {
      this.receiver_uid = to.params.userIdX;
      this.fetchUser(this.receiver_uid);
      this.messages = [];
      this.initial_page = 1;
      this.loading = true;
      this.fetchMessages(to.params.userIdX, to.params.userIdY);
    },
  },
  mounted() {
    this.sender_uid = auth.currentUser.uid;
    this.receiver_uid = this.$route.params.userIdX;
    this.fetchMessages(this.receiver_uid, this.sender_uid);
    this.fetchUser(this.receiver_uid);

    // this.sockets.subscribe(
    //   `receive_message-${this.sender_uid}-${this.receiver_uid}`,
    //   (data) => {
    //     let message = data.message;
    //     message.sender = { _id: message.sender };
    //     this.messages.push(message);
    //     $(".messages").animate({ scrollTop: 20000000 }, "slow");
    //   }
    // );
    // Get registration token. Initially this makes a network call, once retrieved
    // subsequent calls to getToken will return from cache.

    this.goDown();
  },
};
</script>
